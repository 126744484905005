import { useStore } from "@/store";

export function handleError(error, context = "", customMessage = null) {
  const store = useStore();

  if (error.code === "ERR_NETWORK") {
    store.handleMessages(
      false,
      "Falha de conexão. Verifique a conexão com o servidor do backend e tente novamente.",
      "",
      "Erro de conexão"
    );
  } else if (error.code === "ERR_CONNECTION_REFUSED") {
    store.handleMessages(
      false,
      "Conexão recusada. O servidor pode estar fora do ar ou inacessível.",
      "",
      "Erro de conexão"
    );
  } else if (error.code === "ERR_BAD_RESPONSE") {
    store.handleMessages(
      true,
      "Erro ao consultar dados.",
      error.message,
      `Erro ao ${context}`
    );
  } else if (typeof error?.response?.data?.error === "string") {
    const errorMessage = extractErrorMessage(error.response.data.error);
    store.handleMessages(false, errorMessage, "", `Erro ao ${context}`);
  } else if (error.response) {
    switch (error.response.status) {
      case 404:
        store.handleMessages(
          true,
          customMessage || "404 - O recurso solicitado não foi encontrado.",
          error.response.data.error,
          `Erro ao ${context}`
        );
        break;
      case 405:
        store.handleMessages(
          false,
          customMessage ||
            "Ação não permitida. Por favor, verifique se você está realizando a operação correta.",
          "",
          `Erro ao ${context}`
        );
        break;
      default:
        store.handleMessages(
          true,
          `Erro ao ${context}`,
          error.response.data,
          `Erro ao ${context}`
        );
        break;
    }
  } else {
    store.handleMessages(true, "", error, "Erro inesperado");
  }
  store.errorControl(true);
}

function extractErrorMessage(error) {
  return error.includes("mssql:") ? error.split("mssql:")[1]?.trim() : error;
}
