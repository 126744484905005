import { defineStore } from "pinia";

function getInitialDisplayed() {
  const displayed = localStorage.getItem("backoffice_displayed");
  return displayed === null ? true : displayed === "true";
}

function getInitialProfileImage() {
  return localStorage.getItem("profileImage") || "";
}

export const useStore = defineStore("main", {
  state: () => ({
    displayed: getInitialDisplayed(),
    showError: false,
    showAccordion: false,
    errorMessage: "",
    errorAccordionMessage: "",
    errorHeader: "",
    todaysPassword: "",
    error403Handled: false,
    weekPassword: "",
    profileImage: getInitialProfileImage(),
  }),
  actions: {
    toggleSidebarVisibility() {
      this.displayed = !this.displayed;
      this.setDisplayed(this.displayed);
    },
    setDisplayed(value) {
      this.displayed = value;
      localStorage.setItem("backoffice_displayed", value);
    },
    handleMessages(
      showAccordion,
      errorMessage,
      errorAccordionMessage,
      errorHeader
    ) {
      if (this.error403Handled == false) {
        this.showAccordion = showAccordion;
        this.errorMessage = errorMessage;
        this.errorAccordionMessage = errorAccordionMessage;
        this.errorHeader = errorHeader;
      }
    },
    errorControl(value) {
      if (this.error403Handled == false) {
        this.showError = value;
      }
    },
    handleError403(value) {
      this.error403Handled = value;
    },
    handleSupportPassword(password) {
      this.todaysPassword = password;
    },
    handleSupportWeekPassword(password) {
      this.weekPassword = password;
    },
    setProfileImage(imageUrl) {
      this.profileImage = imageUrl;
      localStorage.setItem("profileImage", imageUrl);
    },
  },
});
