import { createRouter, createWebHistory } from "vue-router";
import { getEncryptedItem } from "@/utils/encryptedStorage";
import { clearLocalStorageExcept } from "@/utils/storageUtils";

const LOGIN = "login";
const HOME = "home";
const COMPANY = "company";
const EDIT_COMPANY = "editCompany";
const CREATE_COMPANY = "createCompany";
const CREATE_COMPANY_GROUP = "createCompanyGroup";
const EDIT_COMPANY_GROUP = "editCompanyGroup";
const USER = "user";
const CREATE_USER = "createUser";
const EDIT_USER = "editUser";
const USER_PROFILE = "userProfile";
const ROLE = "role";
const CREATE_ROLE = "createRole";
const EDIT_ROLE = "editRole";
const DEPARTMENT = "department";
const PRODUCTS = "products";
const CONTROL_PANEL = "controlPanel";
const CREATE_VERSION = "createVersion";
const CREATE_MOBILE_VERSION = "createMobileVersion";
const EDIT_VERSION = "editVersion";
const EDIT_MOBILE_VERSION = "editMobileVersion";
const CREATE_SLIDE = "createSlide";
const EDIT_SLIDE = "editSlide";
const SETTINGS = "settings";
const ANALYTICS = "analytics";
const CHATBOT = "chatbot";
const EDIT_ARTICLE = "editChatbotArticle";
const CREATE_ARTICLE = "createChatbotArticle";
const DOCS = "docs";

const routes = [
  {
    path: "/login",
    name: LOGIN,
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/",
    name: HOME,
    component: () => import("../views/Home.vue"),
    meta: { requiresAuth: true },
  },
  // Análises
  {
    path: "/analises",
    name: ANALYTICS,
    component: () => import("../views/Analytics/Analytics.vue"),
    meta: {
      requiresAuth: true,
      permission: [
        "getOperations",
        "getTelemetry",
        "getDevPoints",
        "getImplPoints",
        "getSupPoints",
        "getCustomerQuery",
        "getCommercialPerformance",
        "getManagement",
        "getClientRevenue",
        "getFinance",
      ],
    },
  },

  // Empresas
  {
    path: "/empresas/:activeTab",
    name: COMPANY,
    component: () => import("../views/Company/CompanySection.vue"),
    props: (route) => ({ activeTab: route.params.activeTab }),
    meta: {
      requiresAuth: true,
      permission: [
        "getCompanies",
        "getCompany",
        "getCompanyGroups",
        "getSegment",
      ],
    },
  },
  {
    path: "/empresas/editar-empresa/:mode/:id",
    name: EDIT_COMPANY,
    component: () => import("../views/Company/EditCompany.vue"),
    props: true,
    meta: { requiresAuth: true, permission: ["getCompany", "editCompany"] },
  },
  {
    path: "/empresas/criar-empresa/:mode",
    name: CREATE_COMPANY,
    component: () => import("../views/Company/EditCompany.vue"),
    props: true,
    meta: { requiresAuth: true, permission: "createCompany" },
  },

  // Empresas // Grupos
  {
    path: "/empresas/criar-grupo/:mode",
    name: CREATE_COMPANY_GROUP,
    component: () => import("../views/Company/EditCompanyGroup.vue"),
    props: true,
    meta: { requiresAuth: true, permission: "createCompanyGroup" },
  },
  {
    path: "/empresas/editar-grupo/:mode/:groupId",
    name: EDIT_COMPANY_GROUP,
    component: () => import("../views/Company/EditCompanyGroup.vue"),
    props: true,
    meta: {
      requiresAuth: true,
      permission: ["getCompanyGroup", "editCompanyGroup"],
    },
  },

  // Usuarios
  {
    path: "/usuarios/:activeTab",
    name: USER,
    component: () => import("../views/User/UserSection.vue"),
    props: (route) => ({ activeTab: route.params.activeTab || "0" }),
    meta: {
      requiresAuth: true,
      permission: ["getUser", "getDepartment", "getRoles"],
    },
  },
  {
    path: "/usuarios/criar-usuario/:mode",
    name: CREATE_USER,
    component: () => import("../views/User/EditUser.vue"),
    props: true,
    meta: { requiresAuth: true, permission: "createUser" },
  },
  {
    path: "/usuarios/editar-usuario/:mode/:id",
    name: EDIT_USER,
    component: () => import("../views/User/EditUser.vue"),
    props: true,
    meta: { requiresAuth: true, permission: ["editUser"] },
  },
  {
    path: "/usuarios/editar-perfil",
    name: USER_PROFILE,
    component: () => import("../views/User/UserProfile.vue"),
    meta: { requiresAuth: true },
  },

  // Usuarios // Papeis
  {
    path: "/usuarios/papeis",
    name: ROLE,
    component: () => import("../views/User/Role.vue"),
    meta: { requiresAuth: true, permission: "getRoles" },
  },
  {
    path: "/usuarios/criar-papel/:mode",
    name: CREATE_ROLE,
    component: () => import("../views/User/EditRole.vue"),
    props: true,
    meta: { requiresAuth: true, permission: "createRole" },
  },
  {
    path: "/usuarios/editar-papel/:mode/:id",
    name: EDIT_ROLE,
    component: () => import("../views/User/EditRole.vue"),
    props: true,
    meta: { requiresAuth: true, permission: ["getRole", "editRole"] },
  },

  // Usuarios // Departamentos
  {
    path: "/usuarios/departamentos",
    name: DEPARTMENT,
    component: () => import("../views/User/Department.vue"),
    meta: { requiresAuth: true, permission: "getDepartment" },
  },

  // Produtos
  {
    path: "/produtos/:activeTab",
    name: PRODUCTS,
    component: () => import("../views/Product/ProductSection.vue"),
    props: (route) => ({ activeTab: route.params.activeTab }),
    meta: {
      requiresAuth: true,
      permission: ["getProduct", "getOpusBkp", "getFluxusMobile"],
    },
  },

  // Painel de Controle
  {
    path: "/painel-controle/:activeTab",
    name: CONTROL_PANEL,
    component: () => import("../views/ControlPanel/ControlPanelSection.vue"),
    props: (route) => ({ activeTab: route.params.activeTab }),
    meta: {
      requiresAuth: true,
      permission: ["getVersion", "getSlide", "getRevenue"],
    },
  },

  // Painel de Controle // Versoes
  {
    path: "/painel-controle/criar-versao/:mode",
    name: CREATE_VERSION,
    component: () => import("../views/ControlPanel/EditVersion.vue"),
    props: true,
    meta: { requiresAuth: true, permission: "createVersion" },
  },
  {
    path: "/painel-controle/editar-versao/:mode/:idVer/:code/:type/:versionNum",
    name: EDIT_VERSION,
    component: () => import("../views/ControlPanel/EditVersion.vue"),
    props: true,
    meta: { requiresAuth: true, permission: "editVersion" },
  },
  {
    path: "/painel-controle/criar-versao-mobile/:mode",
    name: CREATE_MOBILE_VERSION,
    component: () => import("../views/ControlPanel/EditMobileVersion.vue"),
    props: true,
    meta: { requiresAuth: true, permission: "createVersion" },
  },
  {
    path: "/painel-controle/editar-versao-mobile/:mode/:idVer",
    name: EDIT_MOBILE_VERSION,
    component: () => import("../views/ControlPanel/EditMobileVersion.vue"),
    props: true,
    meta: { requiresAuth: true, permission: "editVersion" },
  },

  // Painel de Controle // Slides
  {
    path: "/painel-controle/criar-slide/:mode",
    name: CREATE_SLIDE,
    component: () => import("../views/ControlPanel/EditSlide.vue"),
    props: true,
    meta: { requiresAuth: true, permission: "createSlide" },
  },
  {
    path: "/painel-controle/editar-slide/:mode/:id",
    name: EDIT_SLIDE,
    component: () => import("../views/ControlPanel/EditSlide.vue"),
    props: true,
    meta: { requiresAuth: true, permission: "editSlide" },
  },

  // Configurações
  {
    path: "/configuracoes/:activeTab",
    name: SETTINGS,
    component: () => import("../views/Settings.vue"),
    props: (route) => ({ activeTab: route.params.activeTab }),
    meta: { requiresAuth: true, permission: "getSettings" },
  },

  // Chatbot
  {
    path: "/chatbot/:activeTab",
    name: CHATBOT,
    component: () => import("../views/Chatbot/ChatbotSection.vue"),
    props: (route) => ({ activeTab: route.params.activeTab }),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/chatbot/editar-artigo/:mode/:id",
    name: EDIT_ARTICLE,
    component: () => import("../views/Chatbot/EditChatbotArticle.vue"),
    props: true,
    meta: { requiresAuth: true, permission: "editChatbotArticles" },
  },
  {
    path: "/chatbot/criar-artigo/:mode",
    name: CREATE_ARTICLE,
    component: () => import("../views/Chatbot/EditChatbotArticle.vue"),
    props: true,
    meta: { requiresAuth: true, permission: "createChatbotArticles" },
  },
  // Technical Documentation
  {
    path: "/documentacao",
    name: DOCS,
    component: () => import("../views/Docs/Docs.vue"),
    meta: { requiresAuth: true, permission: "getTechnicalDocs" },
  },
  // 404 NotFound
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("../views/NotFound.vue"),
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isAuthenticated = localStorage.getItem("backoffice_token");

  if (requiresAuth && !isAuthenticated) {
    next({ name: LOGIN });
  } else if (requiresAuth) {
    const userPermissions = await getEncryptedItem("backoffice_permissions");
    const hasPermission = to.meta.permission
      ? checkUserPermission(to, JSON.parse(userPermissions))
      : true;
    if (!hasPermission) {
      clearLocalStorageExcept();
      next({ name: LOGIN, query: { sessionExpired: true } });
    } else {
      next();
    }
  } else if (isAuthenticated) {
    next({ name: HOME });
  } else {
    next();
  }
});

function checkUserPermission(toRoute, userPermissions) {
  const permissions = toRoute.meta.permission;
  if (Array.isArray(permissions)) {
    return permissions.some(
      (perm) => userPermissions && userPermissions[perm] === true
    );
  } else {
    return userPermissions && userPermissions[permissions] === true;
  }
}

export default router;
