<template>
  <Dialog
    :visible="this.store.showError"
    :style="{ width: '450px' }"
    modal
    :header="this.store.errorHeader"
  >
    <div class="confirmation-content flex justify-center items-center mb-3">
      <i
        class="pi pi-exclamation-triangle mr-5 text-orange-600"
        style="font-size: 2rem"
      />
      <div>
        <p class="mb-2" v-html="this.store.errorMessage"></p>
        <p v-if="this.store.showAccordion">
          Por favor, tire um <strong>print</strong> do erro abaixo e entre em
          contato com o nosso Suporte Técnico para obter assistência.
        </p>
      </div>
    </div>
    <Panel
      active="false"
      v-if="this.store.showAccordion"
      header="Mostrar erro"
      :toggleable="true"
      :collapsed="true"
    >
      <p class="m-0">
        {{ this.store.errorAccordionMessage }}
      </p>
    </Panel>

    <div>
      <Button class="w-full mt-5" label="Entendi" @click="showMessage" />
    </div>
  </Dialog>
</template>
<script>
import { useStore } from "@/store";

export default {
  computed: {
    store() {
      return useStore();
    },
  },
  methods: {
    showMessage() {
      this.store.handleError403(false);
      this.store.errorControl(false);
    },
  },
};
</script>
